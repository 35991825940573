import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { NavThemeProvider, AppOverlayProvider, AppProvider } from '@Context/AppContext';
import SEO from '@Components/SEO';
import Layout from '@Components/Layout';
import ContentfulModule from '@Src/components/ContentfulModule';
import { IArticlePage, IPageModule } from '@Types/ContentfulTypes';
import BlockLayout from '@Src/components/BlockLayout';
import GetInTouch from '@Src/components/GetInTouch/index';
import '@GraphQL/fragments';

export interface Props {
	location: {
		pathname: string;
	};
	data: {
		contentfulGetInTouchPage: IArticlePage;
	};
}

const GetInTouchPage = ({ data, location }: Props): ReactElement => {
	const { pageModules, title, seoSettings } = data.contentfulGetInTouchPage;
	return (
		<AppProvider type="get-in-touch" path={location.pathname}>
			<AppOverlayProvider>
				<NavThemeProvider theme='dark'>
					<Layout>
						<SEO
							location={location.pathname}
							title={seoSettings?.title || title}
							description={seoSettings?.description}
							// meta={seoSettings?.metaTags}
							image={seoSettings?.image}
						/>
						<BlockLayout>
							<GetInTouch />
						</BlockLayout>
						<BlockLayout>
							{pageModules &&
								pageModules.map(
									(module: IPageModule, index: number): ReactElement => (
										<ContentfulModule
											revealThreashold={index === 0 ? 0 : 0.2}
											key={module.id}
											type={module.type}
											data={module}
										/>
									)
								)}
						</BlockLayout>
					</Layout>
				</NavThemeProvider>
			</AppOverlayProvider>
		</AppProvider>
	);
};

export default GetInTouchPage;

export const query = graphql`
	query {
		contentfulGetInTouchPage {
			... on WPPageNode {
				...PageFields
				... on WPArticlePageNode {
					pageModules {
						... on WPPageModuleNode {
							...InfographicsModule
							...TextBackgroundImageModule
							...TextImageModule
							...TextModule
							...MediaModule
							...VideoModule
							...RichTextModule
							...PersonalCards
							...ProductCards
							...ArticleCards
							...CtaModule
						}
					}
				}
			}
		}
	}
`;
